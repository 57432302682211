import React from "react"
import { LandingPageSubHeader, PageHeader } from "../common/pageHeaders"
import PageText from "../common/pageText"
import { RegBttn, FillerBtn } from "../common/Buttons"
import DeskSvg from "../svg/landing.svg"
import style from "./landing.module.css"

const Landing = () => {
  return (
    <>
      <section>
        <PageHeader>Woo! Welcome</PageHeader>
        <div className={style.bodyLayout}>
          <PageText>
            I’m Hector Benitez, a developer who is eager to create/build apps.
            Whether its solo or in a team, constantly searching for ways to grow
            and learn as a developer.
          </PageText>
          <PageText>
            Building web applications has brought purpose to my life. Hopefully
            you’ll be able to feel it off my personal website!
          </PageText>
        </div>
        <div className={style.mdBodyLayout}>
          <LandingPageSubHeader>Status</LandingPageSubHeader>
          <div className={style.flexBodyLayout}>
            <PageText>Currently, a Software Engineer at ANSOL,inc.</PageText>
            <PageText>
              Even with a position at ANSOL,inc. , I strive to keep up to date
              with modern tech and languages. Here are some project you should
              check out!
            </PageText>
            <RegBttn>Projects</RegBttn>
            <div className={style.smallSection}>
              <p>...or if you are curious and need more info about me</p>

              <FillerBtn>About me!</FillerBtn>
            </div>
          </div>
        </div>
        <img
          src={DeskSvg}
          alt="svg person with Desk computer, laptop and tablet"
          style={{ margin: "0 auto" }}
        />
      </section>
    </>
  )
}

export default Landing
