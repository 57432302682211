import React from "react"
import Layout from "../components/layout"
import Landing from "../components/Landing"

import { LANDING_SEO } from "../config/siteMetaData"

const LandingPage = () => {
  return (
    <Layout {...LANDING_SEO}>
      <Landing />
    </Layout>
  )
}

export default LandingPage
